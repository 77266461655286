const speedLevel1 = 4;
const speedLevel2 = 6;
const speedLevel3 = 8;

const frameLevel1 = 120;
const frameLevel2 = 100;
const frameLevel3 = 90;

export const levels = {
    speedLevel1,
    speedLevel2,
    speedLevel3,
    frameLevel1,
    frameLevel2,
    frameLevel3,
}